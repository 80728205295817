export const pier70Slides = {
    url: [
        "/images/pier-slider-img-1.jpg",
        "/images/pier-slider-img-2.jpg",
        "/images/pier-slider-img-3.jpg",
        "/images/pier-slider-img-4.jpg",
        "/images/pier-slider-img-5.jpg",
        "/images/pier-slider-img-6.jpg",
        "/images/pier-slider-img-7.jpg",
        "/images/pier-slider-img-8.jpg",
        "/images/pier-slider-img-9.jpg",
    ],
    captions: [
        "We interviewed over 150 San Franciscans, who told us that the city is losing its soul.",
        "Reclaiming what San Francisco is all about, we created Pier 70’s purpose “Made of San Francisco” and developed a place brand that celebrates the three things that made the city great in the first place: creativity, openness and the Bay.",
        "",
        "The redevelopment project will uphold the industrial past of the site through the restoration of its most iconic buildings. Building 15’s steel frame will serve as an entry portal into the site and was the inspiration for the marque and pattern of stripes.",
        "",
        "",
        "Bold, confident and a nod to the experience of entering Pier 70, the marque has been expanded into a powerful pattern language that’s bright, distinctive and refreshing.",
        "The brand toolkit is complete with colours that reference this unique city — rust orange, sea blue, steel-painted pink — and a highly impactful typeface with echoes of the pier’s industrial past.",
        ""

    ]
};
export const royalDockSlides = {
    url: [
        "/images/royal-docks/case_study_royal_docks_1.jpg",
        "/images/royal-docks/case_study_royal_docks_2.jpg",
        "/images/royal-docks/case_study_royal_docks_3.jpg",
        "/images/royal-docks/case_study_royal_docks_4.jpg",
        "/images/royal-docks/case_study_royal_docks_5.jpg",
        "/images/royal-docks/case_study_royal_docks_6.jpg",
        "/images/royal-docks/case_study_royal_docks_7.jpg",
        "/images/royal-docks/case_study_royal_docks_8.jpg",
        "/images/royal-docks/case_study_royal_docks_9.jpg",
        "/images/royal-docks/case_study_royal_docks_10.jpg",
    ],
    captions: [
        "The Royal Docks logo takes the R and D from the maritime flag alphabet, making a plus and an equals. The plus is a symbol of positivity, addition, and togetherness. The equals signifies results, doings, and progress. Together, they encapsulate the Royal Docks’ past and future, both as an existing place and a regeneration project that's defining what good growth looks like.",
        "Map the Royal Docks onto central London and it stretches the distance from Hyde Park to Tower Bridge. As complex as a city, we developed a brand with a website at its heart that is a platform for its communities, inviting them to share their stories, activities and products with London and the world.",
        "The website is editorially-rich, written in-house by our own team and full of contemporary, specially commissioned photography. Crucially for the Mayor of London Sadiq Khan and Mayor of Newham Rokhsana Fiaz, the website is helping to encourage local people and businesses to directly participate in shaping plans for the Royal Docks.",
        "",
        "For an area that seemed stuck in visions and plans, the brand we created highlights and celebrates the very real activity happening in the Royal Docks. The monochromatic graphic system doesn’t interrupt or explain, instead it acts as a frame for the people and stories of the area. Even the usual 'vision' document is all about actions — entitled 'What we're doing'.",
        "",
        "Through public engagement we got to know the Royal Docks as local people see it. We identified the four key aspects that really define what they love about the area — its unique water, its industrious culture, its global connections and its proud communities. Imagery captures these aspects while celebrating the area's special sense of scale, everything from its monumental stretches of water to the close personal ties in the area.",
        "Engaging the community in an innovative way — a branded, touring milkfloat."
    ]
}
export const digbethSlides = {
    url: [
        "/images/digbeth/case_study_digbeth_1.jpg",
        "/images/digbeth/case_study_digbeth_2.jpg",
        "/images/digbeth/case_study_digbeth_3.jpg",
        "/images/digbeth/case_study_digbeth_4.jpg",
        "/images/digbeth/case_study_digbeth_5.jpg",
        "/images/digbeth/case_study_digbeth_6.jpg",
        "/images/digbeth/case_study_digbeth_7.jpg",
        "/images/digbeth/case_study_digbeth_8.jpg",
        "/images/digbeth/case_study_digbeth_9.jpg",
    ],
    captions: [
        "The brand celebrates the area’s industrial roots, while also providing an adaptable font for the individuals forging its future. The unmistakable yellow is a nod to the innovative eggless Bird’s custard powder, invented and manufactured in Digbeth from 1837.",
        "",
        "The inventive small businesses that define this emerging area are an eclectic mix — Digbeth is a place where blacksmiths and digital agencies work side by side. So this is a flexible brand, full of personality, exactly like Digbeth itself.",
        "",
        "A typeface for the whole community, Digbeth Sans was created in collaboration with Colophon. It draws on the lettering of canal boats and the industrial Grotesk type that runs throughout the neighborhood and its Victorian goods yards and factories. Free to download, Digbeth Sans has been created to be used by people in the area, making the place brand own-able by the community it represents."
    ]
}

export const museumHomeSlides = {
    url: [
        "/images/museum/case_study_museum_of_the_home_1.jpg",
        "/images/museum/case_study_museum_of_the_home_2.jpg",
        "/images/museum/case_study_museum_of_the_home_3.jpg",
        "/images/museum/case_study_museum_of_the_home_4.jpg",
        "/images/museum/case_study_museum_of_the_home_5.jpg",
        "/images/museum/case_study_museum_of_the_home_6.jpg",
        "/images/museum/case_study_museum_of_the_home_7.jpg",
        "/images/museum/case_study_museum_of_the_home_8.jpg",
        "/images/museum/case_study_museum_of_the_home_9.jpg",
    ],
    captions: [
        "A place to debate what home means — the new brand aims to entice fresh audiences to this East London institution and propel the museum's evolution from historical displays into a platform where diverse perspectives on the idea of the home can be explored, expressed, debated and shared.",
        "We created the museum’s new purpose: to reveal and rethink the ways we live, in order to live better together.",
        "We created a variable typeface to reflect this purpose. We started by exploring how our idea of home is and has always been defined by light and the museum itself is a source of illumination.",
        "",
        "The museum’s manifesto",
        ""
    ]
}