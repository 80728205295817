import React from "react";
import Panel from "../components/templates/panel";
import Content from "../components/templates/content";
import Purpose from "../components/templates/proposal";
import Slider from "../components/templates/slider";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  pier70Slides,
  royalDockSlides,
  digbethSlides,
  museumHomeSlides,
} from "../components/data/sliderData";

import Login from "../components/login";

const StudiesPage = () => {
  return (
    <Layout>
      <Login />
      <SEO title="Studies" />
      <Content>
        <div className="container">
          <h1>Case studies</h1>
          <h2>
            Our case studies demonstrate that we are a strategic and creative
            partner for Downtown West.
          </h2>
          <div className="breaker"></div>
          <div className="row no-gutters">
            <div className="col-md-7">
              <h2>Pier 70</h2>
              <p>
                After years of disuse, this 35-acre site and former shipyard on
                the San Francisco bay is one of the city’s highest profile
                mixed-use redevelopments.
                <br></br>
                <br></br>
                At the same time, San Francisco’s socio-political climate is
                dominated by stark contrasts between ‘tech-bro millionaire
                culture’ vs pervasive homelessness. We created a brand that was
                genuinely made of and for the city.
              </p>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4 fade-text">
              <p>
                <span>
                  What we did
                  <br />
                </span>
                <span>
                  &mdash; Community research
                  <br />
                </span>
                <span>
                  &mdash; Brand strategy
                  <br />
                </span>
                <span>
                  &mdash; Visual identity
                  <br />
                </span>
                <span>
                  &mdash; Design &amp; art direction
                  <br />
                </span>
                <span>
                  &mdash; Web design &amp; development
                  <br />
                </span>
                <span>&mdash; Films</span>
                <br />
                <span>&mdash; Wayfinding</span>
              </p>
            </div>
          </div>
          <br></br>
          <Slider urls={pier70Slides} />
          <div className="breaker"></div>
          <div className="row no-gutters">
            <div className="col-md-7">
              <h2>Royal Docks</h2>
              <p>
                The Royal Docks is London's most important regeneration project,
                covering 1,200 acres and 12 miles of waterfront. But despite its
                proud past as the city's gateway to global trade, it was lost
                from many people's mental map of London. With the Mayor of
                London and Mayor of Newham, we created a brand to celebrate its
                nautical past and created a platform connecting the communities
                living and working around the docks.
              </p>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4 fade-text">
              <p>
                <span>
                  What we did
                  <br />
                </span>
                <span>
                  &mdash; Community research
                  <br />
                </span>
                <span>
                  &mdash; Public consultation
                  <br />
                </span>
                <span>
                  &mdash; Brand strategy
                  <br />
                </span>
                <span>
                  &mdash; Visual identity
                  <br />
                </span>
                <span>
                  &mdash; Design &amp; art direction
                  <br />
                </span>
                <span>
                  &mdash; Web design
                  <br />
                </span>
                <span>
                  &mdash; Content management
                  <br />
                </span>
                <span>
                  &mdash; Extensive editorial
                  <br />
                </span>
                <span>
                  &mdash; Social media strategy &amp; execution
                  <br />
                </span>
                <span>
                  &mdash; Festival sub-branding
                  <br />
                </span>
                &mdash; Exhibition design
                <br />
                <span>
                  &mdash; Signage & wayfinding
                  <br />
                </span>
                <span>&mdash; Local newspaper (in progress)</span>
              </p>
            </div>
          </div>
          <br></br>
          <Slider urls={royalDockSlides} />
          <div className="breaker"></div>
          <div className="row no-gutters">
            <div className="col-md-7">
              <h2>Digbeth</h2>
              <p>
                Birmingham’s former industrial heartland is evolving into an
                enterprising creative neighborhood. Our brief was to create a
                strong voice and place brand that resonated with this
                industrious community of proudly individual businesses. The
                result is a rich typographic identity inspired by the place and
                people of Digbeth.
              </p>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4 fade-text">
              <p>
                What we did <br />
                &mdash; Community research <br />
                &mdash; Brand strategy <br />
                &mdash; Visual identity <br />
                &mdash; Design &amp; art direction <br />
                &mdash; Web design <br />
                &mdash; Content management <br />
                &mdash; Social media strategy <br />
                &mdash; Signage &amp; wayfinding <br />
                &mdash; Brochure design
              </p>
            </div>
          </div>
          <br></br>
          <Slider urls={digbethSlides} />
          <div className="breaker"></div>
          <div className="row no-gutters">
            <div className="col-md-7">
              <h2>Museum of the home</h2>
              <p>
                The Geffrye Museum was undergoing radical redevelopment and
                rethinking. Looking to engage new audiences, we created a new
                brand, name and purpose that drives the museum to have an
                important role in society — to reveal and rethink the ways we
                live, in order to live better together. To become, the Museum of
                the Home.
              </p>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4 fade-text">
              <p>
                What we did <br />
                &mdash; Brand strategy <br />
                &mdash; Visual identity <br />
                &mdash; Design &amp; art direction <br />
                &mdash; Signage &amp; wayfinding
              </p>
            </div>
          </div>
          <br></br>
          <Slider urls={museumHomeSlides} />
        </div>
      </Content>
      <Purpose
        fwd={true}
        bck={true}
        linkBack={"article"}
        titleBack={"Article"}
        linkTo={"summary"}
        titleTo={"Summary"}
      />
    </Layout>
  );
};
export default StudiesPage;
