import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views';



const Slider = ({ children, urls, captions }) => {
    const [slides, setSlides] = useState([urls]);
    const [index, setIndex] = useState(0);
    console.log(slides[0].url.length);
    const handleClickBack = () => {
        if (index > 0) {
            setIndex(prev => prev - 1);
        }
        if (index === 0) {
            setIndex(slides[0].url.length - 1);
        }
    }
    const handleClickForward = () => {

        if (index < slides[0].url.length - 1) {
            setIndex(prev => prev + 1);
        }
        if (index == slides[0].url.length - 1) {
            setIndex(0);
        }

    }
    return (
        <div className="slider">
            <SwipeableViews enableMouseEvents index={index} disabled={true}>
                {slides.map((data) => {
                    return data.url.map((data, id) => {
                        return <div key={id} style={Object.assign({})}>
                            <img src={data} width="100%" height="100%" />
                        </div>
                    })
                })}

            </SwipeableViews>
            <div className="circle-container">
                {
                    slides.map((data, id) => {
                        return data.url.map((data, id) => {
                            return <div key={id} className={index === id ? "circle on" : "circle off"}></div>
                        })
                    })
                }
            </div>
            <SwipeableViews enableMouseEvents index={index} disabled={true}>

                {
                    slides.map((data) => {
                        return data.captions.map((data, id) => {
                            return <div key={id} style={Object.assign({})}><p className="caption-studies" key={id}>{data}</p></div>
                        })
                    })
                }

            </SwipeableViews>
            <img className="arrow-left" onClick={handleClickBack} src="/images/slider-arrow-left.png" />
            <img className="arrow-right" onClick={handleClickForward} src="/images/slider-arrow-right.png" />
        </div>
    )
}
export default Slider;