import React from 'react'

const Content = ({ children }) => {
    return (
        <div className="content-container">
            {children}

        </div>
    )
}
export default Content;