import React from 'react'

const Panel = ({ imgLink, imgSize, height, imgAlt, children, bg }) => {
    const backgroundStyling = {
        backgroundImage: `url(${imgLink})`,
        height: '700px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }
    return (
        <div className={bg ? "panel-bg" : "panel"} style={bg ? backgroundStyling : null}>
            {bg ? <></> : <img src={imgLink} height={height} width={"100%"} alt={imgAlt}></img>}
            <div className="container panel-container">
                {children}
            </div>
        </div>
    )
}
export default Panel;