import React, { Component } from "react";
import "./styles/login.scss";

class Login extends Component {
  state = {
    loggedIn: false,
    password: "",
    error: false,
  };

  handleSubmit = (e) => {
    const { password } = this.state;

    e.preventDefault();

    if (password === "sanjose2020") {
      window.localStorage.loggedIn = "logged-in";
      this.setState({
        loggedIn: true,
        error: false,
      });
    } else {
      this.setState({
        error: true,
        password: "",
      });
    }
  };

  componentDidMount() {
    const loggedIn = window.localStorage.loggedIn
      ? window.localStorage.loggedIn
      : false;

    this.setState({
      loggedIn,
    });
  }

  render() {
    const { loggedIn, password, error } = this.state;

    if (!loggedIn) {
      return (
        <div className="login-modal">
          <div className="login-container">
            <form onSubmit={this.handleSubmit}>
              <p className="login-heading">Hello Google!</p>
              <p>Please input your password to proceed</p>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => {
                  this.setState({ password: e.target.value, error: false });
                }}
              />
              <button>Submit</button>

              {error && (
                <p className="login-error">
                  Incorrect password, please try again.
                </p>
              )}
            </form>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Login;
